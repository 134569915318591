import InnerSlider from './InnerSlider';
import {
  Header,
  Main,
  SearchVideoCardStyled,
  Title,
  HeaderContent,
  HeaderButtons,
  HeaderButton,
  PDFWrapper,
} from './SearchVideoCardWithScreenShot.styled';

import { Video } from '../../../types/contentTypes';

import ym from 'react-yandex-metrika';

import { useLazyGetDocsQuery } from '@/api/timecode/timecodes';
import { StyledLink } from '@/components';
import SearchVideoCard from '@/components/Card/SearchVideoCard/SearchVideoCard';
import FullScreenLoader from '@/components/Shared/FullScreenLo/FullScreenLoader';

interface SearchVideoCardProps {
  videoInfo: Video;
}

export const SearchVideoCardWithScreenShot = ({ videoInfo }: SearchVideoCardProps) => {
  const { publicId, title, videoId } = videoInfo;
  // const [isCollapsed, setIsCollapsed] = useState(false);
  // const [file, setFile] = useState<Blob | null>(null);

  const [getDocs, { data, isSuccess, isLoading }] = useLazyGetDocsQuery();

  const fetchDoc2 = async () => {
    const response = await getDocs(publicId!).unwrap();
    const a = document.createElement('a');
    a.href = response;
    a.target = '_blanc';
    a.download = `${publicId}.pdf`;

    a.click();

    ym('reachGoal', 'SUMMARY');
  };

  const onTimecodesClick = () => {
    ym('reachGoal', 'TIMECODES');
  };

  return (
    <>
      {/* {isCollapsed && (
        <PDFWrapper>
          <HeaderButton theme="white" onClick={() => setIsCollapsed(!isCollapsed)}>
            закрыть
          </HeaderButton>
          {isLoading && <FullScreenLoader />}
          {isSuccess && (
            <div>
              <iframe src={URL.createObjectURL(file || new Blob())} width="100%" height="500px" title={videoId} />
              <iframe src={`${data}.pdf`} width="100%" height="500px" title={videoId} />
            </div>
          )}
        </PDFWrapper>
      )} */}
      <SearchVideoCardStyled>
        <Header>
          <HeaderContent>
            <StyledLink to={`${publicId}`}>
              <SearchVideoCard movie={videoInfo} />
              <Title style={{ marginTop: '10px' }}>{title}</Title>
            </StyledLink>
            <HeaderButtons>
              <HeaderButton theme="white" onClick={fetchDoc2} disabled={isLoading}>
                Конспект
              </HeaderButton>
              <StyledLink to={`${publicId}`}>
                <HeaderButton theme="inline" onClick={onTimecodesClick}>
                  Таймкоды
                </HeaderButton>
              </StyledLink>
              {/* раскомментировать после тестирования */}
              {/* <StyledLink to={`/quiz/${publicId}`}>
                <HeaderButton theme="inline">Тест</HeaderButton>
              </StyledLink> */}
            </HeaderButtons>
          </HeaderContent>
        </Header>
        <Main>
          <InnerSlider items={videoInfo} />
        </Main>
      </SearchVideoCardStyled>
    </>
  );
};
