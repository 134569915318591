import { api } from './api';

import { GetList, Video } from '../types/contentTypes';

import { getSearchParamFromURL } from '@/utils';

const SEARCH_PATH = '/full-search/';
const SUGGESTIONS_PATH = '/suggestions/';

export const searchAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getSearchVideos: build.query<Video[], { query?: string | null }>({
      query: ({ query = '' }) => ({
        url: SEARCH_PATH,
        method: 'GET',
        params: {
          query,
        },
      }),
      transformResponse: (data: Video[]) => {
        return data.map((video) => ({
          ...video,
          cues: video.cues.map((cue) => ({ ...cue, timestampLink: getSearchParamFromURL(cue.timestampLink, 't') })),
        }));
      },
    }),
    getSuggestions: build.query<string[], { query?: string | null }>({
      query: ({ query = '' }) => ({
        url: SUGGESTIONS_PATH,
        method: 'GET',
        params: {
          query,
        },
      }),
    }),
  }),
});

export const {
  useGetSearchVideosQuery,
  useGetSuggestionsQuery,
  useLazyGetSuggestionsQuery,
  useLazyGetSearchVideosQuery,
} = searchAPI;
